import React from 'react'

import "./css/Home.css"
import Slidebar from '../pages/home/Slidebar'
import Services from '../pages/home/Services'
import Whatdo from '../pages/home/Whatedo/Whatdo.jsx'
const Home = () => {
  return (
    <div style={{overFlow:"hidden"}}>
        <Slidebar/>
        <Services/>
        <Whatdo/>
    </div>
  )
}

export default Home
