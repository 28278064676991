const rplan = {
    rplans: [
       {
         id: 1,
       
         
         name:"858 - Jeevan Shanti",
         link:"/Jeevan-Shanti"
        
       },
       {
         id: 2,
        
         
         name:"857 - Single Premium Pension Plan",
         link:"/Single-Premium-Pension-Plan"
        
       },
      
       
      
     ],
   }
   export default rplan