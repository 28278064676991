import React from 'react'
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import '../css/rd.css'
import sd from '../Phtot/MIS-removebg-preview.png'
import { Helmet } from 'react-helmet'
const Mis = () => {
  return (
    <div>
        <Helmet>
        <title>Nisnaifinserv-Savings Monthly Income Account(MIS)</title>
        <meta name="keywords" content="Savings Monthly Income Account-Interest, IndiaPost , Saving Account  " />
        </Helmet>
    <div className='rddiv'>
        <p className='rdh1'>Savings Monthly Income Account(MIS)​</p>


        <div className="rddiv1">

            <img src={sd} alt="" />
            <div className='rddiv2'>
                <div className="rddiv3">
                    <ul>
                        <p>


                        <li style={{listStyle:"none",textAlign:"left"}} ><h4>1. Monthly Income Scheme (MIS): </h4>The name of the scheme to immediately draw attention and interest.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>2. Interest and Rates:   </h4> Emphasize the attractive 7.4% annual interest paid monthly, which offers a consistent income stream.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>3. Deposits: </h4>The minimum deposit of Rs. 1000, making it accessible to a wide range of investors.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>4.Tax Benefits: :</h4> The tax benefits for 5-year deposits, appealing to individuals seeking to optimize their tax liability.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>5. Maturity:  </h4> The option to close the account after 5 years, providing a clear timeframe for investment planning.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>6. Closure:  </h4> The conditions for closure, including the deduction on early closure and the process via application.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>7. Security and Steady Income:  </h4> Reinforce the idea of "secure income" and the "steady income with security" that the scheme offers.</li>

                        </p>


                    </ul>
                </div>


            </div>

        </div>
    </div>
</div>
  )
}

export default Mis
