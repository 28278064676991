import React from 'react'
import "./Endowment.css"
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import hplan from '../JS File/hplan.js';
const Health = () => {
  const {healthplans} =hplan
  return (
    <div>
        <Table responsive>
      <thead>
        <tr>
          <th>Plan</th>
         
        </tr>
      </thead>
      <tbody>
      {healthplans.map((healthplans) => {
            return (
              <tr>
                <td>
                  <Link to={healthplans.link}>{healthplans.name}</Link>
                </td>
              </tr>
            )
          })}

        
      </tbody>
    </Table>
    </div>
  )
}

export default Health
