import React from 'react'
import "./Endowment.css"
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import tplan from '../JS File/termplan';
const Term = () => {
  const{tplans}=tplan
  return (
    <div>
       <Table responsive>
      <thead>
        <tr>
          <th>Plan</th>
         
        </tr>
      </thead>
      <tbody>
      {
        tplans.map((tplans)=>{
          return( 
            <tr>
            <td><Link to={tplans.link}>{tplans.name}</Link></td>
          </tr>

          )
           
        })
      }
       
       
      </tbody>
    </Table>
    </div>
  )
}

export default Term
