import React from 'react'
import "./Endowment.css"
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import childplan from '../JS File/childplan';
const Children = () => {
  const{chplans}= childplan
  return (
    <div>
       <Table responsive>
      <thead>
        <tr>
          <th>Plan</th>
         
        </tr>
      </thead>
      <tbody>
      {chplans.map((chplans) => {
            return (
              <tr>
                <td>
                  <Link to={chplans.link}>{chplans.name}</Link>
                </td>
              </tr>
            )
          })}

       
       
      </tbody>
    </Table>
    </div>
  )
}

export default Children
