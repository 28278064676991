import React from 'react'
import "./footer.css"
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
const Footer = () => {
  return (
    <div className="footerdiv1">


    <div className="footerdiv">
        <div className='footaddre'>
        {/* <img src={img} alt="" /> */}
        <h1>Address</h1>
       <p>
       Nisnai Dhanlaxumi soc Somnath Nager Vadgaonsheri Pune 4
        </p>  
        </div>
        <div className='footall'>
        <p className="footerp"><EmailIcon/> pmore5511@gmail.com<br/> </p>
              
              <p className="footerp"><PhoneIcon/>  +91 9923747178 </p>
        <p className="footerp">< FacebookIcon /> om nisnai consultant<br/> </p>
              
              <p className="footerp"><InstagramIcon/> @_nisnai_finserv_ </p>
        </div>
             
              



    </div>
    <div className="footerdiv2">

    

        <div>
        <p style={{color:"white"}}>© 2023-2024 ADP, Inc. </p>
        </div>

        <div className="link1">
    <a  href="tel:9923747178">
            <PhoneIcon style={{ color: "25D366" }} />
        </a>
        <a href=" https://instagram.com/_nisnai_finserv_?igshid=MWZjMTM2ODFkZg=="  >
            <InstagramIcon style={{ color: "E4405F" }} />
        </a>
        <div >
            <a href=" https://www.facebook.com/profile.php?id=100058540536271&mibextid=ZbWKwL">
                <FacebookIcon style={{ color: "1877F2" }} />

            </a>

        </div>
        {/* <a href="https://www.linkedin.com/in/samarth-gsk-3a4540286/" >
            <LinkedInIcon style={{ color: "0A66C2" }} />
        </a> */}

    </div>
    </div>



</div>
  )
}

export default Footer
