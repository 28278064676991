import React from 'react'
import pf from'./mutual.avif'
import './mutual.css'
const Mutualfund = () => {
  return (
    <div>
    <div className='mutualdiv'>
        <p className='mutualh1'>Mutual Fund Services​</p>


        <div className="mutualdiv1">

            <img src={pf} alt="" className='mutualimg'/>
            <div className='mutualdiv2'>
                <div className="mutualdiv3">
                   
                  <p>
                  Mutual funds are a powerful investment tool that offers diversification, professional management, and the potential for growth. At Nisnai Finserv, we provide comprehensive mutual fund services to help you achieve your financial goals. Our team of financial experts is dedicated to simplifying the investment process, ensuring that you make informed decisions, and creating a customized investment strategy tailored to your needs.                 </p>
                    <ul>
                    <h1>Our Mutual Fund Services:</h1>
                        <p>


                            <li style={{ listStyle: "none", textAlign: "left" }} ><h4>1. Investment Planning:</h4>
                               <p>We work closely with you to understand your financial goals, risk tolerance, and investment horizon. Based on your unique profile, we develop a personalized investment plan.</p>
                               
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>2. Fund Selection:</h4>
                                <p>With access to a wide range of mutual funds, we assist you in selecting funds that align with your investment objectives, whether it's long-term growth, income generation, or capital preservation.</p>
  
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>3.Diversification:</h4>
                                <p>Diversification is key to managing risk. We help you build a diversified portfolio by selecting funds from various asset classes and industries.</p>
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>4.Regular Monitoring:</h4>
                                <p>Our experts continuously monitor your investments to ensure they remain in line with your goals. We also provide updates on fund performance and market trends.</p>
     
                            </li>
                           

                        </p>


                    </ul>
                </div>


            </div>

        </div>
    </div>
</div>
  )
}

export default Mutualfund
