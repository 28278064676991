import React from 'react'

import './health.css'
import pf from './health.jpg'
import { Helmet } from 'react-helmet'
const Healthplans = () => {
  
    return (
      <div>
         <Helmet>
        <title>Nisnaifinserv-Star Health</title>
        </Helmet>
      <div className='healthdiv'>
          <p className='healthh1'>Star Health Insurance Plans​</p>
  
  
          <div className="healthdiv1">
  
              <img src={pf} alt="" className='healthimg'/>
              <div className='healthdiv2'>
                  <div className="healthdiv3">
                    <p>
                    Welcome to Star Health Insurance, a trusted name in health coverage. We offer a range of insurance plans designed to meet your unique healthcare needs. Explore our diverse portfolio of plans:
                    </p>
                      <ul>
                          <p>
  
  
                              <li style={{ listStyle: "none", textAlign: "left" }} ><h4>1. Star Family Health Optima </h4>
                                 <p>A comprehensive family floater plan covering hospitalization expenses, pre and post-hospitalization costs, and day-care procedures for your entire family.</p>
                                 
                              </li>
                              <li style={{ listStyle: "none", textAlign: "left" }}><h4>2. Star Senior Citizens Red Carpet</h4>
                                  <p>Tailored for senior citizens aged 60 and above, this plan provides coverage for medical expenses, including pre-existing conditions, with special features catering to the elderly.</p>
    
                              </li>
                              <li style={{ listStyle: "none", textAlign: "left" }}><h4>3. Star Health Super Surplus</h4>
                                  <p>Enhance your existing coverage with this top-up plan, offering additional protection over your current health insurance policy.</p>
                              </li>
                              <li style={{ listStyle: "none", textAlign: "left" }}><h4>4. Star Comprehensive Insurance:</h4>
                                  <p>A well-rounded plan offering coverage for hospitalization, maternity benefits, and more, along with a no-claim bonus.</p>
       
                              </li>
                              <li style={{ listStyle: "none", textAlign: "left" }}><h4>5. Star Health Gain Insurance:</h4>
                                  <p>A family health insurance policy covering various medical expenses, with added benefits like a health check-up and cumulative bonus.</p>
       
                              </li>
                              <li style={{ listStyle: "none", textAlign: "left" }}><h4>6. Star Cardiac Care Insurance</h4>
                                  <p>Specialized coverage for heart-related ailments and surgeries, addressing specific cardiac healthcare needs.</p>
       
                              </li>
                              <li style={{ listStyle: "none", textAlign: "left" }}><h4>7. Star Micro Health Insurance</h4>
                                  <p>AAn affordable option providing basic coverage for hospitalization expenses and surgeries at a low premium.</p>
       
                              </li>
                            
  
                          </p>
  
  
                      </ul>
                  </div>
  
  
              </div>
  
          </div>
      </div>
  </div>
  )
}

export default Healthplans
