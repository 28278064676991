import React from 'react'
import mb from './photolic/rakshak.jpg'
import './image.css'
const Rekha = () => {
  return (
    <div>
      <img src={mb} alt="" className='eimg' />
    </div>
  )
}

export default Rekha
