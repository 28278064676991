import React from 'react'
import stm from './photolic/tarun.jpg'
import './image.css'
const Tarun = () => {
  return (
    <div>
      <img src={stm} alt="" className='eimg'/>
    </div>
  )
}

export default Tarun
