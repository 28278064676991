import React from 'react'
import stm from './photolic/lakshay.jpg'
import './image.css'
const Laksha = () => {
  return (
    <div>
      <img src={stm} alt="" className='eimg'/>
    </div>
  )
}

export default Laksha
