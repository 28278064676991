import React from 'react'
import pf from'./GST.avif'
import './Gst.css'
const Gst = () => {
  return (
    <div>
    <div className='Gstdiv'>
        <p className='Gsth1'>GST Services​</p>


        <div className="Gstdiv1">

            <img src={pf} alt="" className='Gstimg'/>
            <div className='Gstdiv2'>
                <div className="Gstdiv3">
                   
                  <p>
                  The Goods and Services Tax (GST) has revolutionized the way businesses manage their indirect taxes. At Nisnai Finserv, we offer comprehensive GST services to businesses of all sizes. Our team of GST experts is committed to simplifying GST compliance, optimizing your tax strategy, and ensuring that your business adheres to the latest GST regulations.                  
                    </p>
                    <ul>
                    <h1>Our Services:</h1>
                        <p>


                            <li style={{ listStyle: "none", textAlign: "left" }} ><h4>1.GST Registration:</h4>
                               <p>If your business meets the GST registration threshold, we assist you in the GST registration process, ensuring that you're compliant with all legal requirements.</p>
                               
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>2.GST Return Filing:</h4>
                                <p>Timely and accurate GST return filing is essential to avoid penalties and maintain good compliance. We help businesses prepare and file their GST returns, making the process hassle-free.</p>
  
                            </li>
  
                          

                        </p>


                    </ul>
                </div>


            </div>

        </div>
    </div>
</div>
  )
}

export default Gst
