import React from 'react'
import am from './photolic/amar.jpg'
import './image.css'
const Amar = () => {
  return (
    <div>
      <img src={am} alt="" className='eimg' />
    </div>
  )
}

export default Amar
