import React from 'react'
import pf from'./tax.avif'
import './Account.css'
const Itr = () => {
  return (
    <div>
    <div className='Accountdiv'>
        <p className='Accounth1'>Income Tax Return</p>


        <div className="Accountdiv1">

            <img src={pf} alt="" className='Accountimg'/>
            <div className='Accountdiv2'>
                <div className="Accountdiv3">
                   
                  <p>
                  Filing your Income Tax Return (ITR) accurately and on time is not just a legal obligation but also a crucial financial responsibility. At Nisnai Finserv, we offer comprehensive ITR services to individuals, businesses, and organizations. Our team of experienced tax professionals is committed to simplifying the tax-filing process, optimizing your tax liabilities, and ensuring compliance with tax laws.                   
                  
                    </p>
                    <ul>
                    <h1>Our Services:</h1>
                        <p>


                            <li style={{ listStyle: "none", textAlign: "left" }} ><h4>1. Personalized Tax Filing</h4>
                               <p>We understand that every taxpayer has unique financial situations. Our tax experts provide personalized assistance in preparing and filing your ITR, ensuring that you take advantage of all available deductions and credits.</p>
                               
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>2. Business and Corporate Tax Filing</h4>
                                <p>Businesses face complex tax challenges. We specialize in corporate tax filings, helping businesses navigate the intricacies of tax laws and maximize tax efficiency.</p>
  
                            </li>
                            {/* <li style={{ listStyle: "none", textAlign: "left" }}><h4>3.Audit and Compliance</h4>
                                <p>We provide audit services to ensure your financial records are accurate and in compliance with tax regulations. Our goal is to help you avoid penalties and legal issues.</p>
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>4.Tax Planning and Consultation:</h4>
                                <p>Effective tax planning is key to minimizing your tax liability. Our experts offer strategic tax planning services, helping you make informed financial decisions throughout the year.</p>
     
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>5.Tax Refund Assistance</h4>
                                <p>If you're eligible for a tax refund, we expedite the process to ensure you receive your refund promptly.</p>
     
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>6. E-Filing and Documentation</h4>
                                <p>We facilitate e-filing of ITRs, making the process convenient and efficient. Our team assists in gathering and organizing all required documents for smooth tax filing.</p>
     
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>7.  ITR Verification and Follow-Up</h4>
                                <p>After filing, we assist in verifying your ITR and address any follow-up queries or notices from tax authorities.</p>
     
                            </li>
                           */}

                        </p>


                    </ul>
                </div>


            </div>

        </div>
    </div>
</div>
  )
}

export default Itr
