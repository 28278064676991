import React from 'react'
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import "./Endowment.css"
import lic from '../JS File/lic';
// import endowment from './endowment.js';

const Endowment = () => {
  const { licplan } = lic
  return (
    <div>
      <Table responsive>
        <thead>
          <tr>
            <th>Plan</th>

          </tr>
        </thead>
        <tbody>
          {licplan.map((licplan) => {
            return (
              <tr>
                <td>
                  <Link to={licplan.link}>{licplan.name}</Link>
                </td>
              </tr>
            )
          })}

        </tbody>
      </Table>
    </div>
  )
}

export default Endowment
