import React from 'react'
import arrogya from './photolic/arogya.jpg'
import './image.css'
const Jevanarogya = () => {
  return (
    <div>
      <img src={arrogya} alt="" className='eimg'/>
    </div>
  )
}

export default Jevanarogya
