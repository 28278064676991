import React from 'react'
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import '../css/sc.css'
import sc from '../Phtot/senior-removebg-preview.png'
import { Helmet } from 'react-helmet';
const Scis = () => {
    return (
        <div>
             <Helmet>
        <title>Nisnaifinserv-SCSS</title>
        <meta name="keywords" content=".Senior Citizen Saving SCSS-Interest, IndiaPost , NSC  "/>
        </Helmet>
            <div className='scdiv'>
                <p className='sch1'>Senior Citizen Savings Scheme (SCSS)​</p>


                <div className="scdiv1">

                    <img src={sc} alt="" />
                    <div className='scdiv2'>
                        <div className="scdiv3">
                            <ul>
                                <p>


                                    <li style={{ listStyle: "none", textAlign: "left" }} ><h4>1.Interest and Rates: </h4>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Interest rate: 8.2% per annum.</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Interest payable quarterly on specific dates.</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Dates: 31st March, 30th June, 30th September, 31st December.</li>
                                    </li>
                                    <li style={{ listStyle: "none", textAlign: "left" }}><h4>2. Eligibility:</h4>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Individuals above 60 years of age.</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}> Retired Civilian Employees (55-60 years) within 1 month of retirement.</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Retired Defense Employees (50-60 years) within 1 month of retirement.</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Joint account with spouse allowed.</li>
                                    </li>
                                    <li style={{ listStyle: "none", textAlign: "left" }}><h4>3.Deposit Details:</h4>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Minimum deposit: Rs. 1000, in multiples.</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Maximum deposit: Rs. 30 lakh per individual.</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Excess deposit refunded with PO Savings Account interest.</li>
                                    </li>
                                    <li style={{ listStyle: "none", textAlign: "left" }}><h4>4.Interest Details:</h4>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Interest credited quarterly.</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Unused interest doesn't earn additional interest.</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Interest can be credited to savings account or via ECS.</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Taxable if total interest exceeds Rs. 50,000 in a financial year.</li>
                                    </li>


                                </p>


                            </ul>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default Scis
