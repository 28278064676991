import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import deco from './Photo/decor-image2.png'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import './Photo/home.css'
import './services.css'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
const Services = () => {
  return (
    <div>
        <h1>Our Services</h1>
         <img src={deco} alt="" />
         <div className='card1'>

       
          <Card className="cardmed" style={{ width: '33.33%' }}>
      
      <Card.Body>
        <Card.Title className="symbol">
          <div>

           <CurrencyRupeeIcon/>
          </div>
          </Card.Title>
          <Card.Title>Postal Saving</Card.Title>
        <Card.Text>
        Fixed deposits are suited for conservative investors who don't want to take any sort of risk and are happy with moderate returns only.        
        </Card.Text>
      </Card.Body>
    </Card>
    <Card className="cardmed" style={{ width: '33.33%',marginRight:"20px" }}>
   
      <Card.Body>
      
        <Card.Title className="symbol">
       
          <div>

           <VolunteerActivismOutlinedIcon/>
          </div>
          </Card.Title>
          <Card.Title> Mediclaims</Card.Title>
        <Card.Text>
        Mediclaim is an insurance product which takes care of the expenses you incur in the event of hospitalization or domiciliary care.
        </Card.Text>
        
      </Card.Body>
    </Card>
    <Card style={{ width: '33.33%',marginRight:"20px" }} className="cardmed">
      
      <Card.Body>
      
        <Card.Title className="symbol">
          <div>

           <AccountBalanceIcon/>
          </div>
          </Card.Title>
          <Card.Title> Mutal Fund</Card.Title>
        <Card.Text>
        A mutual fund is a pool of money from numerous investors which is collectively invested by a fund manager.
        </Card.Text>
        
      </Card.Body>
    </Card>
    </div>
        
    </div>
  )
}

export default Services
