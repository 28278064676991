import React from 'react'
import rashak from './photolic/rakshak.jpg'
import './image.css'
const Jevanrashak = () => {
  return (
    <div>
      <img src={rashak} alt="" className='eimg'/>
    </div>
  )
}

export default Jevanrashak
