import React from 'react'
import sar from './photolic/saral.jpg'
import './image.css'
const Saral = () => {
  return (
    <div>
      <img src={sar} alt="" className='eimg'/>
    </div>
  )
}

export default Saral
