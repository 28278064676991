import React from 'react'
import mb from './photolic/dahansanchay.jpg'
import './image.css'
const Sanchay = () => {
  return (
    <div>
      <img src={mb} alt="" className='eimg'/>
    </div>
  )
}

export default Sanchay
