import React from 'react'
import i1 from './photolic/endosment.jpg'
import './image.css'
const Endosementimg = () => {
  return (
    <div>
    <img src={i1} alt="" className='eimg'/>
</div>
  )
}

export default Endosementimg
