const lic = {
    licplan: [
      {
        id: 1,
      
        
        name:"936 - Jeevan Labh (936)",
        link:"/img"
       
      },
      {
        id: 2,
       
        
        name:"914 - New Endowment Plan (914)",
       link:"/endosment"
      },
      {
        id:3,
       
        
        name:"915 - New Jeevan Anand (915)",
        link:"/jeevan"
 
       
      },
      {
        id:4,
       
        
        name:"917 - Single Premium Endowment Plan (917)" ,
        link:"/endosment"
 
       
      },
      {
        id:5,
       
        
        name:"866 - Dhan Barsha plan-866",
        link:"/Dhan-varsha"
 
       
      },
      {
        id:6,
       
       
        name:"868 - Jeevan Azad Plan- 868",
        link:"/jeevan-azad"
 
       
      },
      {
        id:7,
       
       
        name:"869 - Dhan Vriddhi Plan 869",
        link:"/Dhan-veridhi"
 
       
      },
     
    ],
  }
  export default lic