import React from 'react'
import i1 from './jevan.jpg'
import './image.css'
const Isimage = () => {
  return (
    <div>
        <img src={i1} alt="" className='eimg'/>
    </div>
  )
}

export default Isimage
