import React from 'react'
import "../Licplan/lic.css";
import "./business.css";
import mwp from './mwp.jpg';
import Key from './sequre.jpg';    
import emp from './emp.jpg';    
import { useState } from 'react';
const Business = () => {
    const [show,setshow]=useState(false);
    const [show1,setshow1]=useState(false);
    const [show2,setshow2]=useState(false);
  return (
    <div className="accordion">
            
    <div className="accordion-tab">
        <div className='item'>
            <div className="title">
                <h2>MWP </h2>
                <span onClick={()=>setshow(!show)}>{show? " ➖":"➕"}</span>
            </div>
            {show &&  <div className="accordion-content">
                <img src={mwp} alt="" srcset="" className='mimg'/>               
            </div>}
           
        </div>
        <div className='item'>
            <div className="title">
                <h2>Key Man</h2>
                <span onClick={()=>setshow1(!show1)}>{show1? " ➖":"➕"}</span>
            </div>  
            {show1 &&  <div className="accordion-content">
            <img src={Key} alt="" srcset="" className='mimg'/>        
             
            </div>}
           
        </div>
        <div className='item'>
            <div className="title">
                <h2>Employer Employee</h2>
                <span onClick={()=>setshow2(!show2)}>{show2? " ➖":"➕"}</span>
            </div>
            {show2 &&  <div className="accordion-content">
            <img src={emp} alt="" srcset="" className='mimg'/> 
              
            </div>}
           
        </div>
    </div>
    </div>
  )
}

export default Business
