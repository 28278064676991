import React from 'react'
import cancer from './photolic/cancer.jpg'
import './image.css'
const Cancer = () => {
  return (
    <div>
      <img src={cancer} alt="" className='eimg'/>
    </div>
  )
}

export default Cancer
