import React from 'react'
import stm from './photolic/adharstamb.jpg'
import './image.css'
const Stumbh = () => {
  return (
    <div>
      <img src={stm} alt="" className='eimg'/>
    </div>
  )
}

export default Stumbh
