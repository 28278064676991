
import Header from './common/Header';
import Footer from './common/Footer';
import Home from './componenet/Home';
import './App.css'
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import Contact from './pages/contact us/Contact';
import LIcplan from './componenet/Licplan/LIcplan';
import Business from './componenet/Bussiness/Business';
import Post from './pages/Post/Html/Post';
import Redac from './pages/Post/Html/Redac';
import Time from './pages/Post/Html/Time';
import Mis from './pages/Post/Html/Mis';
import Scis from './pages/Post/Html/Scis';
import Ppf from './pages/Post/Html/Ppf';
import Sukanay from './pages/Post/Html/Sukanay';
import Nsc from './pages/Post/Html/Nsc';
import Kvp from './pages/Post/Html/Kvp';

import Healthplans from './pages/Health/Healthplans';

import Isimage from './pages/Endowment/Image/Isimage';
import Endosementimg from './pages/Endowment/Image/Endosementimg';
import Jevan from './pages/Endowment/Image/Jevan';
import Dahan from './pages/Endowment/Image/Dahan';
import Azad from './pages/Endowment/Image/Azad';
import Vridhi from './pages/Endowment/Image/Vridhi';
import Jevanrashak from './pages/Endowment/Image/Jevanrashak';
import Cancer from './pages/Endowment/Image/Cancer';
import Jevanarogya from './pages/Endowment/Image/Jevanarogya';
import Pensionplus from './pages/Endowment/Image/Pensionplus';
import Nivesh from './pages/Endowment/Image/Nivesh';
import Siip from './pages/Endowment/Image/Siip';
import Endosementplus from './pages/Endowment/Image/Endosementplus';
import Saral from './pages/Endowment/Image/Saral';
import Amar from './pages/Endowment/Image/Amar';
import Moneyback from './pages/Endowment/Image/Moneyback';
import Moneyback25 from './pages/Endowment/Image/Moneyback25';
import Bachat from './pages/Endowment/Image/Bachat';
import Shree from './pages/Endowment/Image/Shree';
import Shiromani from './pages/Endowment/Image/Shiromani';
import Rekha from './pages/Endowment/Image/Rekha';
import Sanchay from './pages/Endowment/Image/Sanchay';
import Childplan from './pages/Endowment/Image/Childplan';
import Laksha from './pages/Endowment/Image/Laksha';
import Tarun from './pages/Endowment/Image/Tarun';
import Shila from './pages/Endowment/Image/Shila';
import Stumbh from './pages/Endowment/Image/Stumbh';
import Aboutus from './pages/aboutus/Aboutus';
import Health from './pages/Endowment/jsx file/Health';
import Account from './pages/Accountant/Account';
import Itr from './pages/Accountant/Itr';
import Gst from './pages/Accountant/Gst';
import Mutualfund from './pages/Accountant/Mutualfund';
import Hdfc from './pages/Accountant/Hdfc';
import Loan from './pages/Accountant/Loan';
import { ScrollRestoration } from "react-router-dom";
import Scroltop from './common/Scroltop';
// import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <div className="App">
      <Router>
          <Scroltop/>
        <Header/>
        <Routes>
      
          <Route exact path='/' element={<Home/>} />
          <Route exact path='/contact' element={<Contact/>} />
          <Route exact path='/lic' element={<LIcplan/>} />
          <Route exact path='/business' element={<Business/>} />
          <Route exact path='/post' element={<Post/>} />
          <Route exact path='/RD' element={<Redac/>} />
          <Route exact path='/TD' element={<Time/>} />
          <Route exact path='/MIS' element={<Mis/>} />
          <Route exact path='/sc' element={<Scis/>} />
          <Route exact path='/ppf' element={<Ppf/>} />

          <Route exact path='/sukanay' element={<Sukanay/>} />
          <Route exact path='/nsc' element={<Nsc/>} />
          <Route exact path='/kvp' element={<Kvp/>} />

          <Route exact path='/health' element={<Healthplans/>}/>
          <Route exact path='/healthintrest' element={<Healthplans/>}/>
          <Route exact path='/img' element={<Isimage/>}/>
          <Route exact path='/endosment' element={<Endosementimg/>}/>
          <Route exact path='/jeevan' element={<Jevan/>}/>
          <Route exact path='/Dhan-varsha' element={<Dahan/>}/>
          <Route exact path='/jeevan-azad' element={<Azad/>}/>
          <Route exact path='/Dhan-veridhi' element={<Vridhi/>}/>
          <Route exact path='/Arogya-Rakshak' element={<Jevanrashak/>}/>
          <Route exact path='/Cancer-Care-Plan' element={<Cancer/>}/>
          <Route exact path='/Jeevan-Arogya' element={<Jevanarogya/>}/>
          <Route exact path='/New-Pension-Plus' element={<Pensionplus/>}/>
          <Route exact path='/LIC-Nivesh-Plus' element={<Nivesh/>}/>
          <Route exact path='/LIC-SIIP' element={<Siip/>}/>
          <Route exact path='/New-Endowment-Plus' element={<Endosementplus/>}/>
          <Route exact path='/Jeevan-Shanti' element={<Endosementplus/>}/>
          <Route exact path='/Single-Premium-Pension-Plan' element={<Endosementplus/>}/>
          <Route exact path='/LIC-Saral-Jeevan-Bima' element={<Saral/>}/>
          <Route exact path='/Jeevan-Amar' element={<Amar/>}/>
          <Route exact path='/New-Money-Back-Plan-20-Years' element={<Moneyback/>}/>
          <Route exact path='/New-Money-Back-Plan-25-Years' element={<Moneyback25/>}/>
          <Route exact path='/Bima-Bachat' element={<Bachat/>}/>
          <Route exact path='/Bima-Shree' element={<Shree/>}/>
          <Route exact path='/Jeevan-Shiromani' element={<Shiromani/>}/>
          <Route exact path='/Dhan-Rekha' element={<Rekha/>}/>
          <Route exact path='/Dhan-Sanchay-Plan' element={<Sanchay/>}/>
          <Route exact path='/New-Children-Money-Back-Plan' element={<Childplan/>}/>
          <Route exact path='/Jeevan-Lakshya' element={<Laksha/>}/>
          <Route exact path='/Jeevan-Tarun' element={<Tarun/>}/>
          <Route exact path='/Aadhaar-Shila' element={<Shila/>}/>
          <Route exact path='/Aadhaar-Stambh' element={<Stumbh/>}/>
       
          <Route exact path='/Aboutus' element={<Aboutus/>}/>
          <Route exact path='/Accountanat' element={<Account/>}/>
          <Route exact path='/ITR' element={<Itr/>}/>
          <Route exact path='/GST' element={<Gst/>}/>
          <Route exact path='/mutual-fund' element={<Mutualfund/>}/>
          <Route exact path='/HDFC' element={<Hdfc/>}/>
                
        </Routes>
          
        <Footer/>
      </Router>
    
     
    </div>
  );
}

export default App;
