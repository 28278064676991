const uplan = {
    uplans: [
       {
         id: 1,
       
         
         name:"935 - New Endowment Plus (935)",
         link:"/New-Endowment-Plus"
        
       },
       {
         id: 2,
        
         
         name:"852 - LIC SIIP",
         link:"/LIC-SIIP"
        
       },
       {
         id: 3,
       
         
         name:"849 - LIC Nivesh Plus",
         link:"/LIC-Nivesh-Plus"
        
       },
       {
         id: 4,
        
         
         name:"867 - New Pension Plus - 867",
         link:"/New-Pension-Plus"
        
       },
      
       
      
     ],
   }
   export default uplan