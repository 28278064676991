import React from 'react'
import pp from './photolic/pensionplus.jpg'
import './image.css'
const Pensionplus = () => {
  return (
    <div>
      <img src={pp} alt="" className='eimg'/>
    </div>
  )
}

export default Pensionplus
