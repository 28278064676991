import React from 'react'
import '../css/sc.css'
import pf from '../Phtot/sukanay.png'
import { Helmet } from 'react-helmet';
const Sukanay = () => {
  return (
    <div>
         <Helmet>
        <title>Nisnaifinserv-Sukanya Samriddhi Yojana</title>
        </Helmet>
    <div className='scdiv'>
        <p className='sch1'>Sukanya Samriddhi Yojana​</p>


        <div className="scdiv1">

            <img src={pf} alt="" />
            <div className='scdiv2'>
                <div className="scdiv3">
                    <ul>
                        <p>


                            <li style={{ listStyle: "none", textAlign: "left" }} ><h4>1. Interest and Rates:</h4>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>8.0% per annum, compounded yearly.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Min. deposit: Rs. 250, Max. deposit: Rs. 1,50,000/year.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Subsequent deposits in multiples of Rs. 50.</li>
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>2. Eligibility and Account Opening:</h4>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Guardian can open for girl child below 10 years.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>One account per girl child in India.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Up to two accounts for twins/triplets.</li>
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>3. Deposits:</h4>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Min. initial deposit: Rs. 250.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Max. deposit: Rs. 1.50 lakh/year.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Deposit anytime till 15 years from opening.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Revive defaulted account by paying Rs. 250 + Rs. 50 for each defaulted year.</li>
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>4. Interest:</h4>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Quarterly interest as per Finance Ministry</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Interest credited annually, tax-free</li>
                            </li>
                          

                        </p>


                    </ul>
                </div>


            </div>

        </div>
    </div>
</div>
  )
}

export default Sukanay
