import React from 'react'
import about from './IMG-20220306-WA0064.jpg'
import './about.css'
import { Helmet } from 'react-helmet'
const Aboutus = () => {
  return (
    <div>
       <Helmet>
        <title>Nisnaifinserv-About Us</title>
        <meta name="keywords" content="Nisnai , LIC , Post , About us" />
        </Helmet>
      {/* <div className="about-us"> */}
      <header>
        <h1>About Us - Nisnai Finserv</h1>
      </header>
      <div className="main">
      <div className="abocontainer1">
      <img src={about} alt="Nisnai Finserv Team" />
      </div>
      <div className="container">
        
        <h2 className='h2'>Our Story</h2>
        <p className='p1'>Nisnai Finserv: Your Trusted Financial Partner

At Nisnai Finserv, we take pride in being your one-stop solution for all your financial planning and investment needs. With over 15 years of experience, we are dedicated to helping you make informed financial decisions to achieve your goals. Our personalized life planning strategies focus on creating, growing, and preserving your wealth.</p>

        <h2 className='h2'>Our Mission</h2>
        <p className='p1'>Our mission is clear: To provide you with a concrete and achievable plan for a secure tomorrow. We work tirelessly to empower you with the financial tools and strategies necessary to protect your future.</p>
        <h2 className='h2'>Our Vision</h2>
        <p className='p1'>At Nisnai Finserv, we envision a future where every family enjoys financial security and the realization of their dreams. We strive to be the beacon of trust and innovation in the financial advisory and insurance industry, setting new standards for excellence. Our unwavering commitment is to empower individuals and families worldwide, ensuring their lasting prosperity and peace of mind.</p>

        <h2 className='h2'>Our Team</h2>
        <p className='p1'>Our team comprises well-trained and experienced professionals who are committed to providing you with the highest level of service. We understand that every financial situation is unique, and we work tirelessly to make a difference in your financial future.</p>
        <p className='p1'>At Nisnai Finserv, we don't just provide financial services; we build relationships and secure tomorrows. Let's embark on this financial journey together. Contact us today to get started.</p>

        <h2 className='h2'>Contact Us</h2>
        <p className='p1'>If you have any questions or would like to get started on your financial journey with us, please feel free to <a href="/contact">contact us</a>.</p>
      </div>
    </div>
     </div>
  )
}

export default Aboutus
