import React from 'react'
import '../css/nsc.css'
import nnsc from '../Phtot/NSC.png'
import { Helmet } from 'react-helmet'
const Nsc = () => {
  return (
    <div>
         <Helmet>
        <title>Nisnaifinserv-NSC</title>
        <meta name="keywords" content="National Savings Certificates-Interest, IndiaPost , NSC  "/>
        </Helmet>
    <div className='nscdiv'>
        <p className='nsch1'>National Savings Certificates​</p>


        <div className="nscdiv1">

            <img src={nnsc} alt="" />
            <div className='nscdiv2'>
                <div className="nscdiv3">
                    <ul>
                        <p>


                            <li style={{ listStyle: "none", textAlign: "left" }} ><h4>1. Interest and Rates:</h4>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>7.7% interest compounded annually, paid at maturity.</li>
                                
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>2. Eligibility and Account Opening:</h4>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Single adults, joint accounts (up to 3 adults).</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Guardians on behalf of minors or persons of unsound mind.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Minors above 10 years.</li>
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>3. Deposits:</h4>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Min. deposit: Rs. 1000, multiples of Rs. 100.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>No maximum limit.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Multiple accounts can be opened.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Deposits qualify for deduction under section 80C</li>
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>4. Transfer of Account:</h4>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Transfers allowed in specific cases: nominee/legal heirs, joint holders, court orders, pledging.</li>

                            </li>
                          

                        </p>


                    </ul>
                </div>


            </div>

        </div>
    </div>
</div>
  )
}

export default Nsc
