const childplan = {
   chplans: [
      {
        id: 1,
      
        
        name:"932 - New Children Money Back Plan (932)",
        link:"/New-Children-Money-Back-Plan"
       
      },
      {
        id: 2,
       
        
        name:"933 - Jeevan Lakshya (933)",
        link:"/Jeevan-Lakshya"
       
      },
      {
        id:3,
       
        
        name:"934 - Jeevan Tarun (934)",
        link:"/Jeevan-Tarun"
       
      },
      {
        id:4,
       
        
        name:"944 - Aadhaar Shila (944)",
        link:"/Aadhaar-Shila"
       
      },
      {
        id:5,
       
        
        name:"943 - Aadhaar Stambh (943)",
        link:"/Aadhaar-Stambh"
       
      },
      
     
    ],
  }
  export default childplan