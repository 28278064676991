const tplan = {
    tplans: [
       {
         id: 1,
       
         
         name:"859 - LIC Saral Jeevan Bima",
         link:"/LIC-Saral-Jeevan-Bima"
        
       },
       {
         id: 2,
        
         
         name:"955 - Jeevan Amar",
         link:"/Jeevan-Amar"
        
       },
      
       
      
     ],
   }
   export default tplan