import React from 'react'
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import '../css/rd.css'
import rd from '../Phtot/rd-PhotoRoom.png-PhotoRoom.png'
import { Helmet } from 'react-helmet';
const Redac = () => {
    return (
        <div>
             <Helmet>
        <title>Nisnaifinserv-RD</title>
        <meta name="keywords" content="Recurring Deposit -Interest, IndiaPost , RD  "/>
        </Helmet>
            <div className='rddiv'>
                <p className='rdh1'>Recurring Deposit Account(RD)​​</p>


                <div className="rddiv1">

                    <img src={rd} alt="" />
                    <div className='rddiv2'>
                        <div className="rddiv3">
                            <ul>
                                <p>


                                    <h1>Deposits and Advanced Deposits: </h1>
                                    <li style={{ listStyle: "none", textAlign: "left" }} ><DoneTwoToneIcon />Open an RD account effortlessly using cash or cheque, with the deposit date based on cheque clearance.</li>
                                    <li style={{ listStyle: "none", textAlign: "left" }}><DoneTwoToneIcon />Begin with a minimum monthly deposit of Rs. 100, in multiples of Rs. 10.</li>
                                    <li style={{ listStyle: "none", textAlign: "left" }}><DoneTwoToneIcon />For accounts opened by the 15th of the month, make subsequent deposits by the 15th. For accounts opened between the 16th and last working day, deposit by month-end.</li>

                                    <h1>Unlock Advanced Features:</h1>
                                    <li style={{ listStyle: "none", textAlign: "left" }} ><DoneTwoToneIcon />Non-discontinued RD accounts can enjoy advanced deposits for up to 5 years.</li>
                                    <li style={{ listStyle: "none", textAlign: "left" }}><DoneTwoToneIcon />Get rebates for advance deposits of 6 months or more.</li>
                                    <li style={{ listStyle: "none", textAlign: "left" }}><DoneTwoToneIcon />Deposit at the account opening or any time afterward to maximize your savings.</li>
                                    <h1>Loan Facility:</h1>
                                    <li style={{ listStyle: "none", textAlign: "left" }} ><DoneTwoToneIcon />After a year of maintaining the account and depositing 12 installments, access a loan of up to 50% of your account's credit balance.</li>
                                    <li style={{ listStyle: "none", textAlign: "left" }}><DoneTwoToneIcon />Repay the loan in a lump sum or via equal monthly installments.</li>
                                    <li style={{ listStyle: "none", textAlign: "left" }}><DoneTwoToneIcon />Enjoy competitive loan interest rates, calculated from withdrawal to repayment.</li>


                                </p>


                            </ul>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default Redac
