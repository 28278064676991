import React from 'react'
import "./Endowment.css"
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import moneyplan from '../JS File/moneyplan';
const Money = () => {
  const{moneyplans}=moneyplan
  return (
    <div>
         <Table responsive>
      <thead>
        <tr>
          <th>Plan</th>
         
        </tr>
      </thead>
      <tbody>
      {
        moneyplans.map((moneyplans)=>{
          return( 
            <tr>
            <td><Link to={moneyplans.link}>{moneyplans.name}</Link></td>
          </tr>

          )
           
        })
      }
        {/* <tr>
          <td><Link>920 - New Money Back Plan 20 Years (920)</Link></td>
       
        </tr>
        <tr>
          <td><Link>921 - New Money Back Plan 25 Years (921)</Link></td>
       
        </tr>
        <tr>
          <td><Link>916 - Bima Bachat (916)</Link></td>
       
        </tr>
        <tr>
          <td><Link>948 - Bima Shree (948)</Link></td>
       
        </tr>
        <tr>
          <td><Link>947 - Jeevan Shiromani ( 947)</Link></td>
       
        </tr>
        <tr>
          <td><Link> 863 - Dhan Rekha (863)</Link></td>
       
        </tr>
        <tr>
          <td><Link>865 - Dhan Sanchay Plan ( 865 )</Link></td>
       
        </tr> */}
       
       
       
      </tbody>
    </Table>
      
    </div>
  )
}

export default Money
