// import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './logo.png';
import './css/header.css';
import { Link } from 'react-router-dom';


function Header() {
 
  return (
    <Navbar expand="lg" className="bg-body-tertiary"  sticky="top">
    
      <Container >
        <Navbar.Brand href="#home"><img src={logo} alt="" className='image'/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end">
          
           <Link to='/'  className="nav-link">
           <Nav >Home</Nav>
           </Link>
           
            

            <NavDropdown title="LIC" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1"><Link to='/lic' className="nav-link" preventScrollReset={true}><Nav>LIC Plan</Nav></Link></NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
              <Link to='/business' preventScrollReset={true}><Nav>

              Business Plan
              </Nav>
              </Link>
              </NavDropdown.Item>
             
            </NavDropdown>
           
           
            <NavDropdown title=" POST" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">
              <Link to='/post'>
              <Nav>
              Post Office Savings Account(SB)
              </Nav>
              </Link>
               
                
                </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
              <Link to='/RD'>
              <Nav>
              National Savings Recurring Deposit Account(RD)
              </Nav>
              </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
              <Link to='/TD'>
              <Nav>
              National Savings Time Deposit Account(TD)
              </Nav>
              </Link>
               
                
                </NavDropdown.Item>
              
              <NavDropdown.Item href="#action/3.4">
              <Link to='/MIS'>
              <Nav>
              National Savings Monthly Income Account(MIS)
              </Nav>
              </Link>
             
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">
              <Link to='/sc'>
              <Nav>
              Senior Citizens Savings Scheme Account(SCSS)
              </Nav>
              </Link>
              
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">
              <Link to='/ppf'>
              <Nav>
              Public Provident Fund Account(PPF )
              </Nav>
              </Link>
              
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">
              <Link to='/sukanay'>
              <Nav>
              Sukanya Samriddhi Account(SSA)
              </Nav>
              </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">
              <Link to='/nsc'>
              <Nav>
              National Savings Certificates (VIIIth Issue) (NSC)
              </Nav>
              </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">
              <Link to='/kvp'>
              <Nav>
              Kisan Vikas Patra(KVP)
              </Nav>
              </Link>
              ​</NavDropdown.Item>
            </NavDropdown>
             <Link to='/health' className="nav-link">
             <Nav>STAR HEALTH</Nav>
             </Link>

            <Link to="/Aboutus" className="nav-link">
            <Nav>About US</Nav>
            </Link>

           
           
           <Link to='/contact' className="nav-link js-scroll-trigger">
           
              <Nav>Contact Us</Nav>
           </Link> 
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;