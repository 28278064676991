import React from 'react'
import '../css/sc.css'
import pf from '../Phtot/ppf-PhotoRoom.png'
import { Helmet } from 'react-helmet';
const Ppf = () => {
  return (
    <div>
         <Helmet>
        <title>Nisnaifinserv-PPF</title>
        <meta name="keywords" content="PPF, IndiaPost , Public Provided FUND"/>
        </Helmet>
      <div>
            <div className='scdiv'>
                <p className='sch1'>Public Provident Fund (PPF)​</p>


                <div className="scdiv1">

                    <img src={pf} alt="" />
                    <div className='scdiv2'>
                        <div className="scdiv3">
                            <ul>
                                <p>


                                    <li style={{ listStyle: "none", textAlign: "left" }} ><h4>1. Deposit Details: </h4>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Min. deposit: Rs. 500/year</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Max. deposit: Rs. 1.50 lakh/year</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Deposits in multiples of Rs. 50</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Eligible for Section 80C deduction</li>
                                    </li>
                                    <li style={{ listStyle: "none", textAlign: "left" }}><h4>2. Eligibility:</h4>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Individuals, minors (by guardians)</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Deposits via cash/cheque (cheque realization date is opening date)</li>
                                    </li>
                                    <li style={{ listStyle: "none", textAlign: "left" }}><h4>3.Interest:</h4>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Quarterly interest as per Finance Ministry</li>
                                        <li style={{ listStyle: "inside", textAlign: "left" }}>Interest credited annually, tax-free</li>
                                    </li>
                                  

                                </p>


                            </ul>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default Ppf

