import React from 'react'
import si from './photolic/siip.jpg'
import './image.css'
const Siip = () => {
  return (
    <div>
      <img src={si} alt="" className='eimg'/>
    </div>
  )
}

export default Siip
