import React from 'react'
import pf from'./account.avif'
import './Account.css'
const Account = () => {
  return (
    <div>
    <div className='Accountdiv'>
        <p className='Accounth1'>Expert Accounting Services​</p>


        <div className="Accountdiv1">

            <img src={pf} alt="" className='Accountimg'/>
            <div className='Accountdiv2'>
                <div className="Accountdiv3">
                   
                  <p>
                  we offer expert accounting services to individuals and businesses, helping you navigate the complexities of financial management with confidence. Our team of seasoned accountants is dedicated to providing accurate, comprehensive, and tailored solutions to meet your financial needs. Whether you require assistance with tax planning, financial analysis, or general bookkeeping, we're here to empower your financial success.                  </p>
                    <ul>
                    <h1>Our Services:</h1>
                        <p>


                            <li style={{ listStyle: "none", textAlign: "left" }} ><h4>1.Tax Planning and Compliance:</h4>
                               <p>Our certified tax experts stay up-to-date with the latest tax laws and regulations. We'll help you optimize your tax strategy, ensure compliance, and maximize deductions to minimize your tax liability.</p>
                               
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>2.Financial Statement Preparation:</h4>
                                <p>We specialize in crafting financial statements that provide a clear snapshot of your financial Account. Our balance sheets, income statements, and cash flow statements are invaluable tools for informed decision-making.</p>
  
                            </li>
                          
                          

                        </p>


                    </ul>
                </div>


            </div>

        </div>
    </div>
</div>
  )
}

export default Account
