import React from 'react'
import '../css/sc.css'
import pf from '../Phtot/kvp (1).png'
import { Helmet } from 'react-helmet'
const Kvp = () => {
  return (
    <div>
        <Helmet>
        <title>Nisnaifinserv-Kisan Vikas Patra (KVP)</title>
        <meta name="keywords" content="Kisan Vikas Patra (KVP)-Interest , IndiaPost ,KVP  " />
        </Helmet>
    <div className='scdiv'>
        <p className='sch1'>Kisan Vikas Patra (KVP)​</p>


        <div className="scdiv1">

            <img src={pf} alt="" />
            <div className='scdiv2'>
                <div className="scdiv3">
                    <ul>
                        <p>


                            <li style={{ listStyle: "none", textAlign: "left" }} ><h4>1. Deposit Details: </h4>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Min. deposit: Rs. 1000, multiples of Rs. 100.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>No maximum limit.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Multiple accounts can be opened.</li>
                               
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>2. Eligibility:</h4>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Single adults, joint accounts (up to 3 adults).</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Guardians on behalf of minors or persons of unsound mind.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Minors above 10 years.</li>
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>3. Interest:</h4>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>7.5% interest compounded annually.</li>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Amount invested doubles in 115 months (9 years & 7 months).</li>
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>4. Transfer of Account:</h4>
                                <li style={{ listStyle: "inside", textAlign: "left" }}>Transfers allowed in specific cases: nominee/legal heirs, joint holders, court orders, pledging.</li>
     
                            </li>
                          

                        </p>


                    </ul>
                </div>


            </div>

        </div>
    </div>
</div>
  )
}

export default Kvp
