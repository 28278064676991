const moneyplan = {
    moneyplans: [
       {
         id: 1,
       
         
         name:"920 - New Money Back Plan 20 Years (920)",
         link:"/New-Money-Back-Plan-20-Years"
        
       },
       {
         id: 2,
        
         
         name:"921 - New Money Back Plan 25 Years (921)",
         link:"/New-Money-Back-Plan-25-Years"
        
       },
       {
         id:3,
        
         
         name:"916 - Bima Bachat (916)",
         link:"/Bima-Bachat"
        
       },
       {
         id:4,
        
         
         name:"948 - Bima Shree (948)",
         link:"/Bima-Shree"
        
       },
       {
         id:5,
        
         
         name:"947 - Jeevan Shiromani ( 947)",
         link:"/Jeevan-Shiromani"
        
       },
       {
         id:6,
        
         
         name:" 863 - Dhan Rekha (863)",
         link:"/Dhan-Rekha"
        
       },
       {
         id:7,
        
         
         name:"865 - Dhan Sanchay Plan ( 865 )",
         link:"/Dhan-Sanchay-Plan"
        
       },
       
      
     ],
   }
   export default moneyplan