import React from 'react'
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import '../css/rd.css'
import rd from '../Phtot/td-removebg-preview.png'
import { Helmet } from 'react-helmet';
const Time = () => {
  return (
    <div>
        <Helmet>
        <title>Nisnaifinserv-Sukanya Samriddhi Yojana</title>
        </Helmet>
    <div className='rddiv'>
        <p className='rdh1'> Time Deposit Account​​</p>


        <div className="rddiv1">

            <img src={rd} alt="" />
            <div className='rddiv2'>
                <div className="rddiv3">
                    <ul>
                        <p>


                        <li style={{listStyle:"none",textAlign:"left"}} ><h4>1. High Interest Rates:</h4> Enjoy competitive interest rates across different time periods, providing a strong foundation for your investments.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>2. Flexible Account Options:  </h4> Our account is open to individuals, joint account holders, guardians for minors, and even minors above 10 years. No restrictions on the number of accounts you can open.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>3. Tax-Saving Opportunities:</h4>Make the most of tax benefits under Section 80C with our 5-year Time Deposit. Secure your future while reducing your tax liability.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>4. Maturity and Extensions: :</h4> Watch your investment grow as your deposit matures. Plus, after maturity, extend your account to continue earning attractive returns.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>5. Secure Pledging: </h4> Need collateral? Pledge your Time Deposit Account for security purposes, offering you peace of mind in times of need.</li>

                        </p>


                    </ul>
                </div>


            </div>

        </div>
    </div>
</div>
  )
}

export default Time
