import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import lic from "./photo/lifeinsurance.jpg"
import deco from '../Photo/decor-image2.png';
import ourservices from './ourservices';
import LinkIcon from '@mui/icons-material/Link';
import "./whatdo.css"
import { Link } from 'react-router-dom';
// import { Margin } from '@mui/icons-material';
const Whatdo = () => {
    const {productItems}= ourservices
  return (
    <div>
         <h1>What We Do</h1>
         <img src={deco} alt="" />
      
   
    <div className='product'>

    {productItems.map((productItem)=>{
      return(
        <div className="div1">


    <div className="card2">
      <div className="card-image">

        <img className="image2" src={productItem.cover} alt="" srcset="" />
        <h1>{productItem.name}</h1>
      </div>
      <div className='cardbody'>
        <Link to={productItem.link} className='a1'>
          <LinkIcon/>
        </Link>
      </div>
    </div>
    </div>
     )
    })
  }
  </div>
    </div>
  )
}

export default Whatdo
