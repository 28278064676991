const hplan = {
    healthplans: [
      {
        id: 1,
      
        
        name:"904 - Jeevan Arogya",
        link:"/Jeevan-Arogya"
       
      },
      {
        id: 2,
       
        
        name:"905 - Cancer Care Plan",
        link:"/Cancer-Care-Plan"
      },
      {
        id:3,
       
        
        name:"906 - Arogya Rakshak",

        link:"/Arogya-Rakshak"
       
      },
      
     
    ],
  }
  export default hplan