import React from 'react'
import '../css/post.css'
import { Helmet } from 'react-helmet'
import account from '../Phtot/senior-removebg-preview.png'
const Post = () => {
  return (
    <div className='accountdiv'>
       <Helmet>
        <title>Nisnaifinserv-Savings Account</title>
        <meta name="keywords" content="National Savings Certificates-Interest, IndiaPost , NSC  "/>
        </Helmet>
    <p className='accounth1'>Saving Account</p>

    
    <div className="accountdiv1">
    
    <img src={account} alt="" style={{}} />
    <div className='accountdiv2'>
      <div className="accountdiv3">
      <ul>
                 <p> 
    
<p className='postp'>Welcome to our savings account platform, where your financial security is our top priority. We've outlined the essential guidelines for account opening, deposits, withdrawals, and interest earnings. Please take a moment to familiarize yourself with these rules.   </p> 
     <h1>Who Can Open an Account: </h1>
     <li style={{listStyle:"none",textAlign:"left"}} ><h4>Single Adult:</h4> Any individual who is an adult can open a single account in their name.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>Joint Account by Two Adults: </h4> A joint account can be opened by two adults, designated as Joint A or Joint B.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>Guardian on Behalf of a Minor:</h4>A guardian can open an account on behalf of a minor.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>Guardian on Behalf of a Person of Unsound Mind:</h4>  A guardian can also open an account on behalf of a person of unsound mind.</li>
     <li style={{listStyle:"none",textAlign:"left"}}><h4>Minor Above 10 Years: </h4>  A minor above 10 years old can open an account in their own name.
     </li>
    
    </p>
   
                 
                     </ul>
      </div>
    
       
    </div>
    
    </div>
    </div>
    
  )
}

export default Post
