import React from 'react'
import "./Endowment.css"
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import uplan from '../JS File/uplan';
const Ulip = () => {
  const{uplans}=uplan
  return (
    <div>
       <Table responsive>
      <thead>
        <tr>
          <th>Plan</th>
         
        </tr>
      </thead>
      <tbody>
      {
        uplans.map((uplans)=>{
          return( 
            <tr>
            <td><Link to={uplans.link}>{uplans.name}</Link></td>
          </tr>

          )
           
        })
      }
       
       
      </tbody>
    </Table>
    </div>
  )
}

export default Ulip
