import React from 'react';
import "./lic.css";
import { useState } from 'react';

import Endowment from '../../pages/Endowment/jsx file/Endowment';
import Health from '../../pages/Endowment/jsx file/Health.jsx';
import Retairment from '../../pages/Endowment/jsx file/Retairment';
import Ulip from '../../pages/Endowment/jsx file/Ulip';
import Term from '../../pages/Endowment/jsx file/Term';
import Money from '../../pages/Endowment/jsx file/Money';
import Children from '../../pages/Endowment/jsx file/Children';
const LIcplan = () => {
    const [show,setshow]=useState(false);
    const [show1,setshow1]=useState(false);
    const [show2,setshow2]=useState(false);
    const [show3,setshow3]=useState(false);
    const [show4,setshow4]=useState(false);
    const [show5,setshow5]=useState(false);
    const [show6,setshow6]=useState(false);
    const [show7,setshow7]=useState(false);
  return (
    
        <div className="accordion">
            
            <div className="accordion-tab">
                <div className='item'>
                    <div className="title">
                        <h2>Endowment Plans </h2>
                        <span onClick={()=>setshow(!show)}>{show? " ➖":"➕"}</span>
                    </div>
                    {show &&  <div className="accordion-content">
                        <p>Endowment plan is term insurance policy with maturity benefits. In case of demise of policyholder, the Sum Assured along with accrued bonuses (if any) is paid to the beneficiary. On survival of policyholder, the same is paid on the maturity date.</p>
                        <Endowment/>
                    </div>}
                   
                </div>
                <div className='item'>
                    <div className="title">
                        <h2>Health Insurance Plan</h2>
                        <span onClick={()=>setshow1(!show1)}>{show1? " ➖":"➕"}</span>
                    </div>
                    {show1 &&  <div className="accordion-content">
                        <p>Health plans from LIC give you the financial security to meet health related contingencies.</p>
                        <Health/>
                    </div>}
                   
                </div>
                <div className='item'>
                    <div className="title">
                        <h2>Retirement Solutions</h2>
                        <span onClick={()=>setshow2(!show2)}>{show2? " ➖":"➕"}</span>
                    </div>
                    {show2 &&  <div className="accordion-content">
                        <p>Plan now for a great and independent retired life. Choose from Immediate and deferred annuity plans to suit your retirement needs.</p>
                        <Retairment/>
                    </div>}
                   
                </div>
                <div className='item'>
                    <div className="title">
                        <h2>ULIP Plans</h2>
                        <span onClick={()=>setshow3(!show3)}>{show3? " ➖":"➕"}</span>
                    </div>
                    {show3 &&  <div className="accordion-content">
                        <p>Ulip plans are investment plans for those who realise the worth of hard-earned money. These plans help you see your savings yield rich benefits and help you save tax even if you dont have consistent income.</p>
                        <Ulip/>
                    </div>}
                   
                </div>
               
                <div className='item'>
                    <div className="title">
                        <h2>Term Insurance Plans</h2>
                        <span onClick={()=>setshow5(!show5)}>{show5? " ➖":"➕"}</span>
                    </div>
                    {show5 &&  <div className="accordion-content">
                        <p>Term Insurance Plans are specially designed to offer only Life Cover at a very low premium. There are short term plans, long term plans and convertible plans in this category.</p>
                        <Term/>
                    </div>}
                   
                </div>
                <div className='item'>
                    <div className="title">
                        <h2>Money Back Plans</h2>
                        <span onClick={()=>setshow6(!show6)}>{show6? " ➖":"➕"}</span>
                    </div>
                    {show6 &&  <div className="accordion-content">
                        <p>A bunch of perfect plans offered by LIC to meet the insurance needs and the period financial needs of an Individual. These plans provide cash inflow at a predefined intervals.</p>
                        <Money/>
                    </div>}
                   
                </div>
                <div className='item'>
                    <div className="title">
                        <h2>Child Protection Plans</h2>
                        <span onClick={()=>setshow7(!show7)}>{show7? " ➖":"➕"}</span>
                    </div>
                    {show7 &&  <div className="accordion-content">
                        <p>Children Plans from L.I.C. Provides safeguard and planned savings for the future of your children with respect to funding their upbringing, education, career, marriage, etc. Numbers of options are available to satisfy and match your goals for your children.</p>
                        <Children/>
                    </div>}
                   
                </div>
            </div>
          
            
        </div>
    
  )
}

export default LIcplan
