import React from 'react'
import stm from './photolic/newchild.jpg'
import './image.css'
const Childplan = () => {
  return (
    <div>
      <img src={stm} alt="" className='eimg'/>
    </div>
  )
}

export default Childplan
