import React from 'react'
import pf from'./bank.avif'
import './mutual.css'
const Hdfc = () => {
  return (
    <div>
    <div className='mutualdiv'>
        <p className='mutualh1'>HDFC Bank Services​</p>


        <div className="mutualdiv1">

            <img src={pf} alt="" className='mutualimg'/>
            <div className='mutualdiv2'>
                <div className="mutualdiv3">
                   
                  <p>
                  Mutual funds are a powerful investment tool that offers diversification, professional management, and the potential for growth. At Nisnai Finserv, we provide comprehensive mutual fund services to help you achieve your financial goals. Our team of financial experts is dedicated to simplifying the investment process, ensuring that you make informed decisions, and creating a customized investment strategy tailored to your needs.                 </p>
                    <ul>
                    <h1>Our HDFC Bank Services:</h1>
                        <p>


                            <li style={{ listStyle: "none", textAlign: "left" }} ><h4>1.Savings and Current Accounts:</h4>
                               <p>Explore a range of savings and current account options designed to suit your financial preferences. Enjoy competitive interest rates, seamless online banking, and convenient access to your funds.</p>
                               
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>2.Loan Solutions:</h4>
                                <p>Whether you're looking for a home loan, personal loan, or business loan, our partnership with HDFC Bank allows us to offer you competitive loan products with flexible terms and quick approval processes.</p>
  
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>3.Investment and Wealth Management:</h4>
                                <p>Grow your wealth and achieve your financial goals with our investment and wealth management services. Explore a variety of investment options, including mutual funds, fixed deposits, and more.</p>
                            </li>
                            <li style={{ listStyle: "none", textAlign: "left" }}><h4>4.Insurance Solutions:</h4>
                                <p>Protect what matters most with comprehensive insurance coverage. From life insurance to health insurance and general insurance, we offer a range of insurance solutions to safeguard your financial well-being.</p>
     
                            </li>
                           

                        </p>


                    </ul>
                </div>


            </div>

        </div>
    </div>
</div>
  )
}

export default Hdfc
