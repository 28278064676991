import React from 'react'
import i1 from './photolic/azad.jpg'
import './image.css'
const Azad = () => {
  return (
    <div>
       <img src={i1} alt="" className='eimg' />
    </div>
  )
}

export default Azad
