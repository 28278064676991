import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Home1 from './Photo/home1.jpeg';
import home2 from './Photo/home2.jpg';
import home3 from './Photo/home3.jpg';
import "./slidebar.css"
const Slidebar = () => {
  return (
    <div>
         <Carousel>
      <Carousel.Item interval={1000}>
       <img src={Home1} alt=""  className="slide"/>
      </Carousel.Item>
      <Carousel.Item interval={500}>
      <img src={home2} alt=""  className="slide" />
      </Carousel.Item>
      <Carousel.Item>
      <img src={home3} alt=""  className="slide"/>
      </Carousel.Item>
    </Carousel>
    </div>
  )
}

export default Slidebar
