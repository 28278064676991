const ourservices = {
    productItems: [
      {
        id:1,
       
        cover: "./photo/loan.jpg",
        name:"Life Insurence",
        link:"/lic"
       
      },
     
      {
        id: 2,
       
        cover: "./photo/post.jpeg",
        name:"Account",
        link:"/Accountanat"
       
      },
      {
        id:3,
       
        cover: "./photo/starhealth2.png",
        name:"ITR",
        link:"/ITR"
       
      },
      {
        id:4,
       
        cover: "./photo/mutualfund.jpg",
        name:"Mutual Fund " ,
        link:"/mutual-fund"
       
      },
      {
        id:5,
       
        cover: "./photo/newindia2.avif",
        name:"GST",
        link:"/GST"
       
      },
      {
        id: 6,
      
        cover: "./photo/Hdfc.jpg",
        name:"HDFC",
        link:"/HDFC"
       
      },
     
    ],
  }
  export default ourservices