import React from 'react'
import { useEffect} from "react";
import { useLocation } from "react-router-dom";
const Scroltop = () => {
    const { pathname} = useLocation();
    useEffect (() => {
    window.scrollTo(0, 0);
    }, [pathname]);
    return (
    <div>
      
    </div>
  )
}

export default Scroltop
