import React from 'react'
import i1 from './photolic/dahanvridi.jpg'
import './image.css'
const Vridhi = () => {
  return (
    <div>
       <img src={i1} alt="" className='eimg'/>
    </div>
  )
}

export default Vridhi
