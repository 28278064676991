import React from 'react'
import mb from './photolic/shiromani.jpg'
import './image.css'
const Shiromani = () => {
  return (
    <div>
      <img src={mb} alt="" className='eimg'/>
    </div>
  )
}

export default Shiromani
