import React from 'react'
import "./Endowment.css"
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import rplan from '../JS File/rplan';
const Retairment = () => {
  const{rplans}=rplan
  return (
    <div>
         <Table responsive>
      <thead>
        <tr>
          <th>Plan</th>
         
        </tr>
      </thead>
      <tbody>
      {
        rplans.map((rplans)=>{
          return( 
            <tr>
            <td><Link to={rplans.link}>{rplans.name}</Link></td>
          </tr>

          )
           
        })
      }
       
       
       
      </tbody>
    </Table>
      
    </div>
  )
}

export default Retairment
