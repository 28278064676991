import React from 'react'
import stm from './photolic/shila.jpg'
import './image.css'
const Shila = () => {
  return (
    <div>
      <img src={stm} alt="" className='eimg'/>
    </div>
  )
}

export default Shila
